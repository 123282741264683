<template>
  <AddOrEditAcquisitionOverlayStyled>
    <header>{{ title }}</header>
    <template v-if="!isConfirming">
      <AcquisitionManageMolecule
        :isLoading="false"
        :selectedOrganization="selectedOrganization"
        :disabledOrganizationSelector="true"
        :selectedProduct="selectedProduct"
        :selectedProductTier="selectedProductTier"
        :selectedAcquisitionType="selectedAcquisitionType"
        :organizations="[]"
        :productsAvailable="productsAvailable"
        :productsTierAvailable="productsTierAvailableForOrganization"
        :acquisitionsTypes="acquisitionsTypes"
        :productsTierNotAvailable="productsTierNotAvailable"
        @update:selectOrganization="
          org => {
            selectedOrganizationId = org.id
          }
        "
        @update:selectProduct="
          p => {
            selectedProductId = p.id
          }
        "
        @update:selectProductTier="
          t => {
            selectedProductTierId = t.id
          }
        "
        @update:selectAcquisitionType="selectedAcquisitionType = $event"
      />
      <div class="action">
        <Button
          :label="buttonLabel"
          :disabled="!selectedOrganization || !selectedProduct || !selectedProductTier"
          @click="confirmCreateAcquisition"
        />
      </div>
    </template>
    <ConfirmAcquisitionStyled v-if="isConfirming">
      <div>
        Are you sure you want to assign
        <b> {{ this.selectedProductTier?.name }} </b> of product <b> {{ this.selectedProduct?.name }} </b> to
        <b> {{ this.selectedOrganization?.name }} </b> in <b>{{ this.selectedAcquisitionType }}</b> state
      </div>
      <div class="action">
        <Button :label="$t('actions.cancel')" @click="cancelConfirmCreateAcquisition" :disabled="isMutationRunning" />
        <Button :label="$t('actions.ok')" @click="createOrUpdateAcquisition" :disabled="isMutationRunning" :isLoading="isMutationRunning" />
      </div>
    </ConfirmAcquisitionStyled>
  </AddOrEditAcquisitionOverlayStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { FlashMessages } from '@common/singletons'

import AcquisitionManageMolecule from '@/pages/Acquisition/Molecules/AcquisitionManageMolecule'
import Button from '@/components/Atomic/Atoms/ButtonAtom.vue'

import LIST_PRODUCTS_QUERY from '#/graphql/acquisition/listProductsWithTierLevel.gql'
import ACQUISITIONS_TYPES from '#/graphql/acquisition/listAcquisitionStatusEnum.gql'
import CREATE_ONE_ACQUISITION from '#/graphql/acquisition/createOneAcquisition.gql'
import ORGANIZATION_QUERY from '#/graphql/organizations/show.gql'
import UPDATE_ONE_ACQUISITION from '#/graphql/acquisition/updateOneAcquisition.gql'

const AddOrEditAcquisitionOverlayStyled = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;
  header {
    margin-bottom: 1.5rem;
  }
  .action {
    display: flex;
    justify-content: end;
    margin-top: 1rem;
    gap: 1rem;
  }
`
const ConfirmAcquisitionStyled = styled('div')`
  .action {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
`

export default {
  components: {
    AcquisitionManageMolecule,
    AddOrEditAcquisitionOverlayStyled,
    ConfirmAcquisitionStyled,
    Button,
  },
  props: {
    args: {
      type: Object,
    },
  },
  data() {
    return {
      products: [],
      selectedProductId: null,
      selectedProductTierId: null,
      acquisitionsTypes: [],
      selectedAcquisitionType: 'PURCHASED',
      isConfirming: false,
      isMutationRunning: false,
    }
  },
  computed: {
    title() {
      return this.args?.mode === 'add' ? this.$t('titles.createAcquisition') : this.$t('actions.updateAcquisition')
    },
    selectedOrganization() {
      return this.args.organization
    },
    selectedProduct() {
      return this.products.find(p => p.id === this.selectedProductId)
    },
    selectedProductTier() {
      return this.productsTierAvailable.find(t => t.id === this.selectedProductTierId)
    },
    productsAvailable() {
      return this.products.map(p => ({
        ...p,
        name: `${p.name} (${p.type})`,
      }))
    },
    tierLevelsAllreadyAcquiredByOrganization() {
      return this.selectedOrganization.acquisitions.map(a => a?.productTierLevel?.id)
    },
    tierLevelsOfSelectedProductFromOrganization() {
      return this.selectedOrganization.acquisitions
        .filter(a => {
          return a?.productTierLevel?.product?.id === this.selectedProduct.id
        })
        .map(t => t?.productTierLevel?.tierLevel)
    },
    productsTierAvailable() {
      if (!this.selectedProduct) {
        return []
      } else {
        return this.selectedProduct.tierLevels.map(t => ({
          ...t,
          name: `Tier ${t.tierLevel}`,
        }))
      }
    },
    productsTierAvailableForOrganization() {
      return this.args?.mode === 'edit'
        ? this.productsTierAvailable?.filter(t => !this.tierLevelsAllreadyAcquiredByOrganization?.includes(t.id))
        : this.productsTierAvailable?.filter(t => {
            return (
              !this.tierLevelsAllreadyAcquiredByOrganization.includes(t.id) &&
              this.tierLevelsOfSelectedProductFromOrganization.every(tl => t.tierLevel > tl)
            )
          })
    },
    productsTierNotAvailable() {
      return this.selectedProduct && this.productsTierAvailableForOrganization.length === 0
    },
    buttonLabel() {
      return this.args?.mode === 'edit' ? 'Update Acquisition' : 'Assign Product'
    },
  },
  methods: {
    assignDefaultValues() {
      if (this.args?.mode === 'edit' && this.args?.acquisition) {
        this.selectedProductId = this.args?.acquisition?.productTierLevel?.product?.id
        this.selectedProductTierId = this.args?.acquisition?.productTierLevel?.id
      }
    },
    confirmCreateAcquisition() {
      this.isConfirming = true
    },
    cancelConfirmCreateAcquisition() {
      this.isConfirming = false
    },
    createOrUpdateAcquisition() {
      if (this.args?.mode === 'edit') {
        this.updateAcquisition()
      } else {
        this.createAcquisition()
      }
    },
    async createAcquisition() {
      try {
        this.isMutationRunning = true
        await this.$apollo.mutate({
          mutation: CREATE_ONE_ACQUISITION,
          variables: {
            data: {
              product: {
                connect: {
                  id: this.selectedProduct?.id,
                },
              },
              productTierLevel: {
                connect: {
                  id: this.selectedProductTierId,
                },
              },
              status: this.selectedAcquisitionType,
              organizationId: this.selectedOrganization.id,
              orderedAt: this.selectedAcquisitionType === 'ORDERED' ? DateTime.now() : null,
              trialStartedAt: this.selectedAcquisitionType === 'TRIAL_STARTED' ? DateTime.now() : null,
              purchasedAt: this.selectedAcquisitionType === 'PURCHASED' ? DateTime.now() : null,
            },
          },
          update: async (store, { data }) => {
            const acquisition = data.createOneAcquisition
            const { organization } = store.readQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.selectedOrganization?.id,
                },
              },
            })
            organization.acquisitions.push(acquisition)
            store.writeQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.selectedOrganization?.id,
                },
              },
              data: {
                organization,
              },
            })
          },
        })
        FlashMessages.$emit('success', this.$t('messages.entityCreatedSuccessfully', { entity: this.$tc('acquisition', 1) }), {
          timeout: 3000,
        })
        this.isMutationRunning = false
        this.$root.$emit('closeOverlay')
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async updateAcquisition() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_ONE_ACQUISITION,
          variables: {
            where: {
              id: this.args?.acquisition?.id,
            },
            data: {
              product: {
                connect: {
                  id: this.selectedProduct?.id,
                },
              },
              productTierLevel: {
                connect: {
                  id: this.selectedProductTierId,
                },
              },
              status: {
                set: this.selectedAcquisitionType,
              },
              orderedAt: {
                set: this.selectedAcquisitionType === 'ORDERED' ? DateTime.now() : null,
              },
              trialStartedAt: {
                set: this.selectedAcquisitionType === 'TRIAL_STARTED' ? DateTime.now() : null,
              },
              purchasedAt: {
                set: this.selectedAcquisitionType === 'PURCHASED' ? DateTime.now() : null,
              },
            },
          },
          update: async (store, { data }) => {
            const acquisition = data.updateOneAcquisition
            const { organization } = store.readQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.selectedOrganization?.id,
                },
              },
            })
            const acquisitionIndex = organization.acquisitions.findIndex(a => a.id === this.args?.acquisition?.id)
            organization.acquisitions[acquisitionIndex] = acquisition
            store.writeQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.selectedOrganization?.id,
                },
              },
              data: {
                organization,
              },
            })
          },
        })
        FlashMessages.$emit('success', this.$t('messages.entityUpdatedSuccessfully', { entity: this.$tc('acquisition', 1) }), {
          timeout: 3000,
        })
        this.isMutationRunning = false
        this.$root.$emit('closeOverlay')
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    products: {
      query: LIST_PRODUCTS_QUERY,
    },
    acquisitionsTypes: {
      query: ACQUISITIONS_TYPES,
      update({ acquisitionsTypes }) {
        return !acquisitionsTypes?.enumValues ? [] : acquisitionsTypes?.enumValues.map(t => t.name)
      },
    },
  },
  mounted() {
    this.assignDefaultValues()
  },
}
</script>
