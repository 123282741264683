var render = function () {
  var _this$selectedProduct, _this$selectedProduct2, _this$selectedOrganiz;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AddOrEditAcquisitionOverlayStyled', [_c('header', [_vm._v(_vm._s(_vm.title))]), !_vm.isConfirming ? [_c('AcquisitionManageMolecule', {
    attrs: {
      "isLoading": false,
      "selectedOrganization": _vm.selectedOrganization,
      "disabledOrganizationSelector": true,
      "selectedProduct": _vm.selectedProduct,
      "selectedProductTier": _vm.selectedProductTier,
      "selectedAcquisitionType": _vm.selectedAcquisitionType,
      "organizations": [],
      "productsAvailable": _vm.productsAvailable,
      "productsTierAvailable": _vm.productsTierAvailableForOrganization,
      "acquisitionsTypes": _vm.acquisitionsTypes,
      "productsTierNotAvailable": _vm.productsTierNotAvailable
    },
    on: {
      "update:selectOrganization": function updateSelectOrganization(org) {
        _vm.selectedOrganizationId = org.id;
      },
      "update:selectProduct": function updateSelectProduct(p) {
        _vm.selectedProductId = p.id;
      },
      "update:selectProductTier": function updateSelectProductTier(t) {
        _vm.selectedProductTierId = t.id;
      },
      "update:selectAcquisitionType": function updateSelectAcquisitionType($event) {
        _vm.selectedAcquisitionType = $event;
      }
    }
  }), _c('div', {
    staticClass: "action"
  }, [_c('Button', {
    attrs: {
      "label": _vm.buttonLabel,
      "disabled": !_vm.selectedOrganization || !_vm.selectedProduct || !_vm.selectedProductTier
    },
    on: {
      "click": _vm.confirmCreateAcquisition
    }
  })], 1)] : _vm._e(), _vm.isConfirming ? _c('ConfirmAcquisitionStyled', [_c('div', [_vm._v(" Are you sure you want to assign "), _c('b', [_vm._v(" " + _vm._s((_this$selectedProduct = this.selectedProductTier) === null || _this$selectedProduct === void 0 ? void 0 : _this$selectedProduct.name) + " ")]), _vm._v(" of product "), _c('b', [_vm._v(" " + _vm._s((_this$selectedProduct2 = this.selectedProduct) === null || _this$selectedProduct2 === void 0 ? void 0 : _this$selectedProduct2.name) + " ")]), _vm._v(" to "), _c('b', [_vm._v(" " + _vm._s((_this$selectedOrganiz = this.selectedOrganization) === null || _this$selectedOrganiz === void 0 ? void 0 : _this$selectedOrganiz.name) + " ")]), _vm._v(" in "), _c('b', [_vm._v(_vm._s(this.selectedAcquisitionType))]), _vm._v(" state ")]), _c('div', {
    staticClass: "action"
  }, [_c('Button', {
    attrs: {
      "label": _vm.$t('actions.cancel'),
      "disabled": _vm.isMutationRunning
    },
    on: {
      "click": _vm.cancelConfirmCreateAcquisition
    }
  }), _c('Button', {
    attrs: {
      "label": _vm.$t('actions.ok'),
      "disabled": _vm.isMutationRunning,
      "isLoading": _vm.isMutationRunning
    },
    on: {
      "click": _vm.createOrUpdateAcquisition
    }
  })], 1)]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }